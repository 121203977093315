#root {
  width: 100%;
  height: 100%;
}

*,
div,
body {
  box-sizing: border-box;
}

/* reactjs-popup styling */
.popup-arrow {
  color: #f6f6f6;
}
/*  */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 5px;

  height: 50px;
}

html,
body {
  background-color: #ececec !important;
  width: 100%;
  height: 100%;
}

html input textarea {
  background: "rgba(0, 0, 0, 0.08)";
}
html.dark input textarea {
  background: "#3D495B";
}

/* className="relative w-[58%]  h-full text-white bg-gradient-to-b from-[#549687] to-[#2f3d55]" */
.left {
  /* float: left; */
  position: relative;
  width: 58%;
  height: 100%;
  /* width: 50%;  */
  color: #fff;
  background: linear-gradient(180deg, #549687 0%, #2f3d55 100%);
}

/* className="relative w-[42%] bg-[#ececec] h-full" */
.right {
  position: relative;
  width: 42%;
  background-color: #ececec;
  height: 100%;
}

/* className="w-full h-screen text-white bg-gradient-to-b from-[#549687] to-[#2f3d55]" */
.link-main-div {
  width: 100%;
  height: 100vh;
  /* width: 50%;  */
  color: #fff;
  background: linear-gradient(180deg, #549687 0%, #2f3d55 100%);
}

.otp {
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 8vh;
  height: 8vh;
  margin-left: 1%;
  margin-right: 1%;
}

/* className="absolute top-1/2 left-[45%] -translate-x-1/2 -translate-y-1/2" */
.left-content {
  position: absolute; /* or absolute */
  top: 50%;
  left: 45%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.link-content {
  text-align: center;
  padding-top: 20%;
  margin-left: 25%;
  width: 50%;
}

/* className="text-2xl text-[#c3d2d2] w-fit " */
.reset-content {
  color: #c3d2d2;
  font-size: 24px;
  width: fit-content;
}

/* className="text-4xl w-fit" */
.reset-heading {
  font-size: 37px;
  width: fit-content;
}
.anim {
  position: absolute;
  bottom: 1px;
  width: 30%;
}

/* className="h-full flex flex-col justify-center items-center" */
.right-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  position: relative;

  width: 2%;
}

/* className="text-base mt-1p mb-3p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10" */
.text-area {
  font-size: 16px;
  margin-top: 1%;
  margin-bottom: 3%;
  width: 100%;
  padding: 15px;
  height: 63px;
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

/* className="flex justify-center items-center" */
.right-content-text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 14%; */
}

/* CSS */
.button-84 {
  margin-top: 3% !important;
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 22px;
  font-weight: 600;
  width: 550px;
  height: 63px;
  background: linear-gradient(180deg, #303e55 0%, #18202c 100%);
  border-radius: 10px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-84:hover {
  box-shadow: rgba(0, 1, 0, 0.2) 0 2px 8px;
  opacity: 0.85;
}

.button-84:active {
  outline: 0;
}

.button-84:focus {
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .button-84 {
    height: 48px;
  }
}

.dashboard-box {
  padding-top: 0px;
  padding-left: 12%;
  margin-right: 4%;
}

.chart-box {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

/* className="text-center mb-1p p-2p" */
.chartpage-grid {
  text-align: center;
  margin-bottom: 1%;
  padding: 2%;
}

/* className="w-full h-[26px] text-center bg-white rounded-b" */
.box-2 {
  /* position: absolute; */
  width: 100%;
  height: 26px;
  /* float: left; */
  text-align: center;
  background: #ffffff;
  border-radius: 0 0 5px;
}

.table-box {
  max-height: 70vh;
  overflow-y: scroll;
  padding-left: 12%;
  margin-top: 4%;
  padding-top: 0px;
  width: 96.1%;
  border-radius: 5px;
}

.second-box {
  height: max-content;
  /* overflow: scroll; */
  padding-left: 20%;
  /* margin-top: 0%; */
  /* padding-top: 0px; */
  width: 96.1%;
}

.team-add-button {
  width: 240px;
  height: 59px;
  /* left: 1228px;
top: 87px; */
  color: #fff !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  border-width: 0;
  margin-bottom: 10px;
  background: linear-gradient(180deg, #303e55 0%, #18202c 100%);
  border-radius: 10px;
}
.team-add-button-modal {
  width: 240px;
  height: 59px;
  /* left: 1228px;
  top: 87px; */
  color: #fff !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center;
  border-width: 0;
  margin-top: 10px !important;
  margin-bottom: 10px;
  background: linear-gradient(180deg, #303e55 0%, #18202c 100%);
  border-radius: 10px;
}

.active-vuln {
  display: flex;
  justify-content: space-between;
  padding-left: 12%;
  margin: 4% 0;
  padding-bottom: 4%;
  border-radius: 5px;

  width: 96.1%;
}
.heading-modal {
  /* font-family: 'Inter'; */
  font-style: normal;
  padding-top: 4%;
  padding-left: 10px !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px;
  text-align: center;
}
.modal-line {
  /* position: absolute; */
  width: 768px;
  margin-top: 2%;
  height: 0px;
  margin-left: 2% !important;
  align-items: center;
  opacity: 0.41;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
}
.modal-last-line {
  /* position: absolute; */
  width: 750px;
  margin-top: 2%;
  height: 0px;
  /* margin-left: 2% !important; */
  align-items: center;
  opacity: 0.41;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
}
.blank-box {
  position: absolute !important;
  width: 806px;
  height: 50px;
  margin-top: 10vh;
  margin-left: 0% !important;
  padding-left: 4%;
  padding-top: 1.5%;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  background: rgba(217, 217, 217, 0.3);
}

.blank-box-edit {
  position: absolute !important;
  width: 806px;
  height: 50px;
  margin-top: 2vh;
  margin-left: 0% !important;
  padding-left: 4%;
  padding-top: 1.5%;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  background: rgba(217, 217, 217, 0.3);
}

/* className="w-1/2 bg-white mr-1p overflow-hidden rounded" */
.active-feed {
  width: 50%;
  /* height: 30%; */
  background-color: #fff;
  /* padding-bottom: 20%; */
  /* padding-left: 3%; */
  margin-right: 1%;
  border-radius: 5px;
  overflow: hidden;
}
.active-feed-activities {
}

.vuln-feed {
  height: 40vh;
}

/* className="w-1/2 bg-white ml-1p overflow-hidden rounded" */
.vulnerability-feed {
  width: 50%;
  background-color: #fff;
  /* padding-left: 3%; */
  margin-left: 1%;
  border-radius: 5px;
  overflow: hidden;
}

.left-chart {
  padding: 1%;
  /* float: left; */
  width: 45%;
}

/* className="mt-1p p-1p pb-0" */
.right-chart {
  margin-top: 1%;
  padding: 0 1% 1% 1%;
}

/* className="overflow-y-scroll max-h-[70vh] border-b4 border rounded" */
.right-chart-table {
  overflow-y: scroll;
  max-height: 70vh;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.box-chart-small {
  box-sizing: border-box;

  /* position: absolute; */
  width: 25px;
  height: 25px;
  text-align: center;

  background: rgba(217, 217, 217, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.nutchart {
  /* box-sizing: border-box; */

  position: relative;
  width: 50%;
  height: 40%;
  left: -2%;
  top: 35vh !important;
}

/* className="flex flex-col items-center bg-greyBg4 rounded font-bold text-center" */
.chart-risk-score {
  margin-top: 20%;
  width: 122px;
  height: 65px;
  background-color: #00f;
  text-align: center !important;
  font-weight: 700;
  font-size: 45px;
  background: rgba(217, 217, 217, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* vertical-align:  !important; */
}

.risk-score-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */
  /* text-align: center; */
}

/* className="flex flex-col justify-between h-max font-semibold min-w-[95px] rounded"  +  box shadow */
.box-number {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  justify-content: space-between;
  height: max-content;
  font-weight: 600;
  min-width: 95px;

  /* left: 358px;
top: 117px; */

  background: #f5f5f5;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.logo {
  width: 40px;
  height: 50px;
  vertical-align: middle;
  padding-right: 3px;
  padding-top: 10px;
  padding-left: 9px;
  margin-bottom: 8px;
  /* padding-bottom: -40%; */
}

.sidebar-icon {
  /* width: 100% !important; */
  float: right !important;
}
li {
  list-style-type: none;
}

/* className="shadow mt-2p bg-white rounded" */
.user-node {
  /* position: relative; */
  margin-top: 2%;

  /* width: 1135px; */
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.first-step {
  /* position: relative; */
  margin-top: 1%;

  /* width: 1135px; */
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.user-blank-strip {
  /* position: absolute; */
  width: 100%;
  height: 49px;
  /* font-family: 'Inter'; */
  /* font-style: normal; */
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 22px;
  padding: 1% 2%;

  background: rgba(217, 217, 217, 0.3);
}

.user-top {
  padding: 3% 5%;
}

.avatar-text {
  display: inline-block !important;
  margin-bottom: 1%;
}
.edit-button {
  float: right;
}

.delete-modal {
  position: absolute;
  width: 806px;
  height: 237px;
  left: 353px;
  top: 176px;
  backdrop-filter: blur(10px);
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.edit-modal {
  position: absolute;
  width: 806px;
  height: 728px;
  left: 353px;
  top: 176px;
  backdrop-filter: blur(10px);
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* className="w-full p-4 text-base my-2p h-15 border-b2 rounded bg-inputBg" */
.first-step-input {
  width: 100%;
  padding: 15px;
  font-size: 16px;

  margin: 2% 0;
  height: 60px;

  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.second-step-input {
  resize: vertical;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 1%;
  border-radius: 5px;
}

.third-step-input {
  font-size: 16px;
  box-sizing: border-box;

  /* position: absolute; */
  width: 100%;
  height: 59px;
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  padding: 15px;

  margin-top: 1%;
}

/* import primarybtn now */
.button-1-step {
  width: 240px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  height: 60px;
  background: linear-gradient(180deg, #303e55 0%, #18202c 100%);
  border-radius: 10px;
}

.button-2-back {
  /* position: absolute; */
  width: 240px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  height: 59px;
  background: rgba(48, 62, 85, 0.15);
  border-radius: 10px;
  border-width: 0px;
}

.layout-button {
  display: "inline-flex";
  vertical-align: "text-bottom" !important;
  box-sizing: "inherit";
  text-align: "center" !important;
  align-items: "center" !important;
}

.filter-button {
  padding: 6px 10px;
  font-size: 14px;
  /* font-weight: 700; */
  color: rgba(0, 0, 0, 0.5);
  /* height: 59px; */
  background: rgba(46, 60, 82, 0.15);
  border: 2px solid rgba(46, 60, 82, 0.3);
  border-radius: 4px;
  font-weight: 600;
}

/* for theme toggle */
.display-none {
  display: none;
}
