.container {
  cursor: pointer;
}

.container input {
  display: none;
}

.container input + div {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  box-shadow: inset 8px -8px 0 0 var(--color-toggle-dark, #000);
  transform: scale(1) rotate(-2deg);
  transition: box-shadow 0.5s ease 0s, transform 0.4s ease 0.1s;
}

.container input + div::before {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  transition: background 0.3s ease;
}

.container input + div::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: -3px 0 0 -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 -13px 0 var(--color-toggle-light, #eee),
    0 13px 0 var(--color-toggle-light, #eee),
    13px 0 0 var(--color-toggle-light, #eee),
    -13px 0 0 var(--color-toggle-light, #eee),
    10px 10px 0 var(--color-toggle-light, #eee),
    -10px 10px 0 var(--color-toggle-light, #eee),
    10px -10px 0 var(--color-toggle-light, #eee),
    -10px -10px 0 var(--color-toggle-light, #eee);
  transform: scale(0);
  transition: all 0.3s ease;
}

.container input:checked + div {
  box-shadow: inset 20px -20px 0 0 #fff;
  transform: scale(0.5) rotate(0deg);
  transition: transform 0.3s ease 0.1s, box-shadow 0.2s ease 0s;
}

.container input:checked + div::before {
  background: var(--color-toggle-light, #eee);
  transition: background 0.3s ease 0.1s;
}

.container input:checked + div::after {
  transform: scale(1.5);
  transition: transform 0.5s ease 0.15s;
}
