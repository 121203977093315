@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  transition: color 0.4s ease-in-out, background 0.4s ease-in-out;
} */

a {
  @apply text-[#1D26EF] dark:text-[#4B65ED] underline;
}
p {
  @apply my-1p;
}
