.container {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-list {
  min-width: 240px;
  max-width: 380px;
  overflow: auto;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.message-list {
  flex: 1;
  min-width: 140px;
  overflow: hidden;
}

.input-area {
  height: 50px;
  background: red;
}

.on-drag-mlist {
  width: 100%;
  height: 100%;
  background-color: #e2f3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.rce-example {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rce-example .rce-example-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
  border-bottom: 1px solid rgb(214, 213, 213);
  width: 100%;
}

.rce-example .rce-example-btn button {
  margin: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  border: none;
}

.rce-example .rce-example-btn button:first-child {
  margin-left: 0;
}

.rce-example .rce-example-btn button:last-child {
  margin-right: 0;
}

.rce-example-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.rce-example-messageList {
  /* background: rgb(246 250 250); */
  /* border: 1px solid rgb(238, 235, 235); */
  /* height: 70vh; */
}

.rce-example-input textarea {
  border: 1px solid rgb(214, 213, 213);
  border-radius: 0.5rem;
  overflow: hidden;
}

.rce-mlist {
  padding: 1rem 0;
}

.rce-container-mbox {
  flex-direction: column;
  display: block;
  overflow-x: hidden;
  min-width: 300px;
}

.rce-mbox-forward {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  /*display: none;*/
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
  cursor: pointer;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  margin: auto;
}

.rce-mbox-forward-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -50px;
}

.rce-mbox-forward-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -50px;
}

.rce-mbox-reply-btn-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -85px;
}

.rce-mbox-reply-btn-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-forward-right {
  opacity: 1;
  visibility: visible;
}

.rce-mbox-remove {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  /*display: none;*/
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
  cursor: pointer;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  margin: auto;
}

.rce-mbox-remove-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -120px;
}

.rce-mbox-remove-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -120px;
}

.rce-container-mbox:hover .rce-mbox-remove-left {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-remove-right {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-left {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-right {
  opacity: 1;
  visibility: visible;
}

.rce-mbox {
  position: relative;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 6px 9px 8px 9px;
  float: left;
  min-width: 140px;

  /* me */
  max-width: 66%;
}

.rce-mbox.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-box-default-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
  from {
    background-color: #fff;
  }
  to {
    background-color: #dfdfdf;
  }
}

.rce-mbox-body {
  margin: 0;
  padding: 0;
  position: relative;
}

.rce-mbox.rce-mbox-right {
  float: right;
  margin-left: 5px;
  margin-right: 20px;
  border-top-right-radius: 0px;
  border-top-left-radius: 5px;
}

.rce-mbox.rce-mbox-right.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-box-right-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-box-right-focus {
  from {
    background-color: #d4f1fb;
  }
  to {
    background-color: #b8dae6;
  }
}

.rce-mbox-text {
  font-size: 13.6px;
  word-break: break-word;
}

.rce-mbox-text-retracted {
  font-style: italic;
  user-select: none;
  display: flex;
  align-items: center;
}

.rce-mbox-text.rce-mbox-text-retracted svg {
  margin-right: 3px;
}

.rce-mbox-text-retracted.left {
  color: #555555b3 !important;
}

.rce-mbox-text-retracted.right {
  color: #efefefb3 !important;
}

.rce-mbox-text:after {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
}

.rce-mbox-time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  right: -4px;
  bottom: -5px;
  user-select: none;
}

.rce-mbox-time.non-copiable:before {
  content: attr(data-text);
}

.rce-mbox-time-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: -6px;
  margin-left: -6px;
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 2px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.33), transparent);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
}

.rce-mbox--clear-padding {
  padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
  border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
  position: absolute;
  right: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: white;
  filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2));
}

.rce-mbox-right-notch.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-right-notch-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-right-notch-focus {
  from {
    fill: #d4f1fb;
  }
  to {
    fill: #b8dae6;
  }
}

.rce-mbox-left-notch {
  position: absolute;
  left: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: white;
}

.rce-mbox-left-notch.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-left-notch-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-left-notch-focus {
  from {
    fill: #fff;
  }
  to {
    fill: #dfdfdf;
  }
}

.rce-mbox-title {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #4f81a1;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.rce-mbox-title:hover {
  text-decoration: underline;
}

.rce-mbox-title--clear {
  margin-bottom: 5px;
}

.rce-mbox-status {
  margin-left: 3px;
  font-size: 15px;
  height: 15px;
}

.rce-mbox-title > .rce-avatar-container {
  margin-right: 5px;
}

/* me */
.rce-mbox-right {
  justify-self: right;
}
.rce-mbox-body {
  display: flex;
  position: relative;
  width: 100%;
}
.rce-mbox-text {
  background: transparent;
  color: black;

  /* for rce-mbox-time */
  margin-right: 45px;
  margin-left: 4px;
  flex-wrap: wrap;
  flex-grow: 1;
}

.rce-mbox-time {
  /* relative to rce-mbox-body */
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  right: 0;
  bottom: 0;
  width: max-content;

  font-size: 12px;
}

.rce-input {
  width: 100%;
  padding: 2%;
  outline: none;
  flex-grow: 1;
  background: transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
